<template>
  <base-layout title="Setup Environment">
    <ion-grid fixed>
      <ion-row>
        <ion-col size="12">
          <ion-list>
            <ion-item
              :router-link="{ name: item.routeName }"

              v-for="(item, index) in appPages"
              :key="index"
              class="link"
            >
              <ion-icon slot="start" :ios="item.iosIcon" :md="item.mdIcon"></ion-icon>
              <ion-label>
                <h2>{{ item.title }}</h2>
                <p>{{ item.description }}</p>
              </ion-label>
            </ion-item>
          </ion-list>
        </ion-col>
      </ion-row>
    </ion-grid>
  </base-layout>
</template>

<script>
import BaseLayout from "../../Base/BaseLayout";
import {
  IonGrid,
  IonRow,
  IonCol,
  IonList,
  IonItem,
  IonIcon,
  IonLabel,
} from "@ionic/vue";

import { bookOutline, bookSharp } from "ionicons/icons";

export default {
  components: {
    BaseLayout,
    IonGrid,
    IonRow,
    IonCol,
    IonList,
    IonItem,
    IonIcon,
    IonLabel,
  },
  data() {
    return {
      appPages: [
        {
          title: "Subjects",
          description: "Create subjects under subject groups",
          routeName: "subject",
          iosIcon: bookOutline,
          mdIcon: bookSharp,
        },
      ],
    };
  },
};
</script>

<style scoped>
</style>
